body {
  overflow-x: hidden;
}
.loading {
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  background-color: #124c62;
  border-radius: 100%;
  height: 6em;
  width: 6em;
}

.container {
  align-items: center;
  background-color: white;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
    transform: scale(1);
  }
}


/* Header */

.containerHeader {
  background-image: url("./media/bgHeader.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.picHeader {
  position: relative;
  margin-top: 1em;
}

.picHeader img {
  border-radius: 50%;
  width: 13em;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.22);
}

.picHeader::after {
  content: "";
  width: 215px;
  height: 215px;
  border-radius: 50%;
  background: linear-gradient(90deg,
      #3dd0e7 0%,
      rgba(205, 220, 57, 1) 100%,
      #7ee73d 100%);
  display: block;
  position: absolute;
  top: -3px;
  left: 8px;
  z-index: -1;
}

.headerMarge {
  margin-left: 0.1rem;
}

.sousTitre {
  font-size: 18px;
}

.textHeader {
  text-align: center;
  font-size: 16px;
}

.buttonHeader {
  background-color: #3dd0e7;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 200px;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 4rem;
}

.buttonHeader:focus {
  outline: 0;
}

.buttonHeader:after {
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.buttonHeader:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .containerHeader {
    width: 100%;
    padding-top: 2em;
    background-size: contain;
  }

  .headerMarge {
    margin-left: 2rem;
  }

  .sousTitre {
    font-size: 24px;
  }

  .textHeader {
    text-align: left;
    font-size: 18px;
    width: 350px;
  }

  .buttonHeader {
    padding: .75rem 3rem;
    font-size: 1.25rem;
    width: 400px;
  }

  .picHeader img {
    width: 300px;
    height: auto;
  }

  .picHeader::after {
    width: 315px;
    height: 315px;
  }
}

/* News */
.news {
  margin-top: 8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.titleNews {
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}

.card {
  box-shadow: 5px 5px 5px #2e2d2d5e;
}

.card-title {
  font-size: 22px;
}

.card-text {
  font-size: 16px;
}

.listOutils {
  color: #12bad4;
  font-size: 16px;
}

.btnNews {
  background-color: #3dd0e7;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 150px;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 3rem;
}

.btnNews:focus {
  outline: 0;
}

.btnNews:after {
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.btnNews:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .news {
    padding-top: 0.5rem;
  }

  .titleNews {
    font-size: 28px;
  }

  .containerNews {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: rgba(158, 140, 96, 0.1);
  }

  .card-title {
    font-size: 24px;
  }

  .card-text {
    font-size: 18px;
  }

  .listOutils {
    display: flex;
    font-size: 17px;
  }

  .btnNews {
    font-size: 18px;
  }
}

/* Compétences-Expérience */

.colonneComp {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.titreComp {
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  font-weight: 700;
  width: fit-content;
  margin: 9rem auto 3rem auto;
}

.compList {
  position: relative;
  width: 100%;
  white-space: nowrap;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  list-style-type: none;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

}

.compList::-webkit-scrollbar {
  width: auto;
  height: 10px;
}

.compList::-webkit-scrollbar-track {
  border-radius: 0;
}

.compList::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgba(158, 140, 96, 0.1);
}

.compContainer {
  display: inline-block;
  width: 200px;
  text-align: center;
  height: 200px;
  border-radius: 15px;
  margin: 5px;
  background-color: rgba(81, 108, 247, 0.16);
}

.compContainer img {
  width: 100px;
  height: auto;
  padding-top: 2.4rem;
}

.compContainer p {
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  font-size: 24px;
  color: rgba(46, 46, 72, 0.62);
  margin-top: 0.8rem;
}

.listExp {
  list-style: url("./media/puce.webp");
  line-height: 1;
  margin-left: 1rem;
}

.expeCont {
  margin: 2rem 2rem;
  position: relative;
  padding-left: 10px;
}

.dateExp {
  color: rgba(46, 46, 72, 0.62);
  font-size: 18px;
  width: 180px;
  line-height: 1.2;
}

.dateExp i {
  font-size: 24px;
  color: rgb(174, 3, 3);
}

.expLogoSm {
  width: 85px;
  height: auto;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.expLogo {
  width: 200px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  z-index: -3000;
}

.titleExp {
  font-size: 26px;
  margin-top: 2rem;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  text-align: center;
}

.sstitExp {
  font-size: 18px;
  font-weight: 600;
  text-align: center;

}

.textExp {
  color: rgba(46, 46, 72, 0.62);
  padding-top: 2rem;
  max-width: 900px;
  font-size: 18px;
}

@media (min-width: 768px) {
  .compList {
    text-align: center;
    -webkit-overflow-scrolling: touch;
  }

  .listExp {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .dateExp {
    width: fit-content;
  }

  .expLogoSm {
    width: 100px;
    margin-right: 3rem;
    float: right;
  }

  .expLogo {
    width: 230px;
    float: right;
  }

  .titleExp,
  .sstitExp {
    text-align: left;
  }

  .textExp {
    font-size: 22px;
  }
}

/* Certification */
.titreCert {
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 30px;
  font-weight: 700;
  width: fit-content;
  margin: 9rem auto 3rem auto;
}

.certcontainer {
  list-style: none;
}

.certification {
  background-color: rgba(81, 108, 247, 0.16);
  max-width: 500px;
  border-radius: 100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.certPic {
  margin: 25px;
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  object-fit: fill;
}

.certText,
.certTitle {
  margin-left: 5rem;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.certText {
  padding-top: 14px;
  padding-right: 14px;
  width: 193px;
}

.certText span {
  color: rgba(46, 46, 72, 0.62);
  float: right;
  font-size: 18px;
  width: 180px;
  font-size: 14px;
}

.certTitle {
  margin-top: 0;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .certification {
    max-width: 800px;
  }

  .certPic {
    width: 80px;
    height: 80px;
    margin: 15px;
  }

  .certText,
  .certTitle {
    margin-left: 7rem;
  }

  .certText {
    width: 100%;
    font-size: 20px;
  }

  .certText span {
    margin-right: 7rem;
    font-size: 17px;
  }

  .certTitle {
    font-size: 26px;
  }
}

/* Education */
.containerEdu {
  max-width: 400px;
  list-style: none;
  margin-right: auto;
  margin-left: auto;
}

.education {
  border-radius: 30px;
  background-color: rgba(158, 140, 96, 0.1);
  position: relative;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  height: 330px;
  margin-top: 1rem;
  margin-right: 10px;
}

.logoEdu,
.logoEduLs {
  width: 140px;
  height: 140px;
  position: absolute;
  object-fit: contain;
  z-index: -1;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoEduLs {
  width: 90px;
  height: 90px;
}

.eduTitle {
  padding: 9rem 10px 0px 10px;
  margin-bottom: 1.5rem;
text-align: center;
}

.eduText {
  margin-left: 1rem;
  max-width: 300px;
}
.enter{
  display:block;
}
.datePlace {
  margin-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.datePlace span {
  float: right;
  color: rgba(0, 0, 0, 0.42);
}

@media (min-width: 768px){
  .containerEdu{
    display: flex;
    margin: 1rem auto 1rem auto;
    max-width: fit-content;
  }
  .education{
    width: 400px;
    height: 360px;
  }
  .eduTitle{
    max-width: min-content;
     margin-left: auto;
     margin-right: auto;
  }
}

/* Footer */
.footerContainer {
  background-image: url("./media/bgFooter.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top:13rem;
}

.titleFooter {
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.contactContainer {
  font-size: 18px;
  color: #12bad4;
  font-weight: bold;
  text-align: center;
}

.contactContainer p {
  padding: 14px;
}

.number {
  border-top: 1px solid #12bad4;
}

.iconContainer a {
  font-size: 2.5rem;
}

.bi-browser-chrome {
  color: #e93c35;
}

.bi-facebook {
  color: #3b5998;
}

.bi-linkedin {
  color: #0a66c2;
}

@media (min-width: 768px) {
  .titleFooter {
    margin: 2em;
    font-size: 28px;
  }

  .contactContainer {
    display: flex;
    justify-content: center;
    font-size: 22px;
  }

  .number {
    border-left: 1px solid #12bad4;
    border-top: none;
  }

  .iconContainer {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}